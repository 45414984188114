import type CSS from "csstype";
import { css, styled } from "styled-components";
import { useForm, SubmitHandler } from "react-hook-form";
import { Toaster, toast } from "react-hot-toast";

import { colors, theme } from "../../../../../constants";
import { Lead } from "../../../../../models/lead";
import {
  Badge,
  Button,
  ButtonTypes,
  FlexColumn,
  FlexItem,
  FlexRow,
  Image,
  Text,
} from "../../../../../shared/styled";
import {
  toReadableDate,
  toReadableDateOnly,
} from "../../../../../utilities/datetime";
import { Priorities, PriorityMap } from "../../../../../models/lead/priorities";
import {
  Icon,
  IconTypes,
  Loading,
  Select,
} from "../../../../../shared/components";
import { StyledNumberInput } from "../../../../../shared/components/Input/StyledBaseInput";
import { parsePhoneNumber } from "../../../../../models/lead/utilities";
import { OperableType } from "../../../../../models/lead/vehicle";
import { getAvatarUrl } from "../../../../../utilities/avatar";
import { useAuth } from "../../../../../contexts";
import { SMS_TEMPLATE_OPTIONS } from "../../../constants";
import { injectMessagingValues } from "../../../../../templates";
import { LONG_SMS } from "../../../../../templates/sms/longSms";
import { SHORT_SMS } from "../../../../../templates/sms/shortSms";
import { MINIMUM_REQUIRED_CALL_TIME_TRESHOLD } from "../../../../../models/event/call";
import {
  BackgroundStyling,
  BackgroundStylingProps,
} from "../../../../../shared/styling";
import {
  httpClaimLead,
  httpUnclaimLead,
  httpUpdateLead,
  httpAssignPrice,
  httpTriggerCall,
} from "../../../../../apis/leads";
import { UIEvent, useMemo, useRef, useState } from "react";
import { LeadNotes } from "../LeadNotes";
import { Event } from "../../../../../models/event";
import {
  LEAD_HIGH_PROVIDERS,
  SUPER_HIGH_PROVIDERS,
} from "../../../../../models/lead/providers";
import { LeadEmailCard } from "../LeadEmailCard";
import { useGlobalData } from "../../../../../contexts/GlobalDataProvider";

interface LeadCardWrapperStyleProps {
  $borderColor?: string;
}

const LeadCardWrapperStyle = styled.div<LeadCardWrapperStyleProps>`
  box-sizing: border-box;
  border: solid 2px
    ${({ $borderColor }) => $borderColor ?? theme?.colors?.primary};
  border-radius: 5px;
  margin-bottom: 25px;
  text-transform: uppercase;
`;

interface LeadCardBodyStyle {
  background?: BackgroundStylingProps | CSS.Property.Background;
}

type FormData = {
  price: string | number;
};

const LeadCardBodyStyle = styled.div<LeadCardBodyStyle>`
  transition: all 0.5s;
  width: 100%;
  height: 200px;
  display: flex;

  box-sizing: border-box;

  ${({ background }) => BackgroundStyling(background || colors.white.clean)};
`;

const LeadCardLocation = styled.div`
  display: flex;
  justify-content: space-evenly;
  box-sizing: border-box;
  align-items: center;
  position: relative;

  box-shadow: 3px 4px 4px ${theme.shadow.color};
  max-width: 450px;
  width: 100%;

  padding: 5px 10px;

  background: ${colors.grey.gallery};

  cursor: pointer;
  gap: 5px;

  border-radius: 8px;
`;

interface LeadCardFooterProps {
  background: string;
}

const LeadCardFooter = styled.div<LeadCardFooterProps>`
  display: flex;
  flex-direction: row;

  height: 50px;
  background: ${({ background }) => background};

  align-items: center;

  padding: 0 16px;
  box-sizing: border-box;
  gap: 10px;

  cursor: pointer;
`;

interface LeadCardDetailsStyleProps {
  isCollapsed?: boolean;
}

const LeadCardDetailsStyle = styled.div<LeadCardDetailsStyleProps>`
  width: 100%;
  height: ${({ isCollapsed }) => (isCollapsed ? "280px" : "0px")};
  padding: ${({ isCollapsed }) => (isCollapsed ? "16px 16px" : "0px 16px")};
  transition: all 0.5s;

  overflow: hidden;

  display: flex;
  flex-direction: row;
  box-sizing: border-box;

  background: ${colors?.white?.clean};
`;

interface LeadCardState {
  isCollapsed?: boolean;
  recommendedPriceCollapsed?: boolean;
  notesCollapsed?: boolean;
  emailBoxCollapsed?: number;
}

interface LeadCardProps {
  data?: Lead;
  scrollData?: UIEvent<HTMLDivElement, globalThis.UIEvent>;
}

export const LeadCard = ({ data, scrollData }: LeadCardProps) => {
  const { currentUser } = useAuth();
  const { emailTemplates } = useGlobalData();

  const ref = useRef<HTMLDivElement>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const [state, setState] = useState<LeadCardState>({});
  const [inputPrice, setInputPrice] = useState<boolean>(false);

  const agent = data?.agent;
  const avatarUrl = getAvatarUrl(
    agent?.first_name || "Unknown",
    agent?.last_name || "Unknown",
    `${agent?.id}`
  );

  const [callerListHoverIndex, setCallerListHoverIndex] = useState<
    number | undefined
  >();

  const priceCalculator = (data?.routes || [])?.find(
    (route) => route?.type === "calculator_vehicle_route_price"
  );
  const routePrices = (data?.routes || [])?.filter(
    (route) => route?.type !== "calculator_vehicle_route_price"
  );
  const notes = data?.notes || [];

  const firstCallDelta = (() => {
    if (!data?.events_map?.first_call) return undefined;

    const currentUtcDate = new Date(data?.created_at)?.getTime();
    const firstCallDate = new Date(
      data?.events_map?.first_call?.created_at
    )?.getTime();

    return firstCallDate - currentUtcDate;
  })();

  const handleCopyOriginPostalCode = async (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    event.stopPropagation();

    if (!data) return;
    const postalCode = `${data.origin_postal_code}`;

    try {
      await navigator.clipboard.writeText(postalCode);
      toast.success("Origin Postal Code Copied", {
        style: {
          border: `1px solid ${theme.colors.primary}`,
          padding: "16px",
          boxShadow: "none",
          borderRadius: "24px",
        },
        iconTheme: {
          primary: `${theme.colors.primary}`,
          secondary: `${theme.colors.clean}`,
        },
      });
    } catch (error) {
      toast.error("Error copying origin postal code", {
        style: {
          border: `1px solid ${theme.colors.danger}`,
          padding: "16px",
          boxShadow: "none",
          borderRadius: "24px",
        },
        iconTheme: {
          primary: `${theme.colors.danger}`,
          secondary: `${theme.colors.clean}`,
        },
      });
    }
  };

  const handleCopyDestinationPostalCode = async (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    event.stopPropagation();
    if (!data) return;
    const postalCode = `${data.destination_postal_code}`;

    try {
      await navigator.clipboard.writeText(postalCode);
      toast.success("Destination postal code copied", {
        style: {
          border: `1px solid ${theme.colors.primary}`,
          padding: "16px",
          boxShadow: "none",
          borderRadius: "24px",
        },
        iconTheme: {
          primary: `${theme.colors.primary}`,
          secondary: `${theme.colors.clean}`,
        },
      });
    } catch (error) {
      toast.error("Error copying destination postal code", {
        style: {
          border: `1px solid ${theme.colors.danger}`,
          padding: "16px",
          boxShadow: "none",
          borderRadius: "24px",
        },
        iconTheme: {
          primary: `${theme.colors.danger}`,
          secondary: `${theme.colors.clean}`,
        },
      });
    }
  };

  const onSubmit: SubmitHandler<FormData> = async (newData) => {
    try {
      if (
        data?.id !== undefined &&
        (typeof newData.price === "number" || typeof newData.price === "string")
      ) {
        await httpAssignPrice(data?.id, newData.price.toString());
        toast.success("Price Assigned Successfully", {
          style: {
            border: `1px solid ${theme.colors.primary}`,
            padding: "16px",
            boxShadow: "none",
            borderRadius: "24px",
          },
          iconTheme: {
            primary: `${theme.colors.primary}`,
            secondary: `${theme.colors.clean}`,
          },
        });
      }
      setInputPrice(false);
    } catch (error) {
      toast.error("Error assigning price", {
        style: {
          border: `1px solid ${theme.colors.danger}`,
          padding: "16px",
          boxShadow: "none",
          borderRadius: "24px",
        },
        iconTheme: {
          primary: `${theme.colors.danger}`,
          secondary: `${theme.colors.clean}`,
        },
      });
      console.error("Error assigning price: ", error);
    }
  };

  const extractNumbers = (inputString: string): string => {
    return inputString.replaceAll(/\D/g, "").replaceAll(" ", "");
  };

  const borderColor = useMemo(() => {
    const config = [SUPER_HIGH_PROVIDERS, LEAD_HIGH_PROVIDERS];
    if (!data?.lead_provider) return theme.lead.normal;

    const match = config.find((providerConfig) =>
      providerConfig.providers.includes(data?.lead_provider as number)
    );
    return match ? match?.color : theme.lead.normal;
  }, [SUPER_HIGH_PROVIDERS, LEAD_HIGH_PROVIDERS]);

  return (
    <>
      {/* TODO Move this Toaster in App.tsx */}
      <Toaster position="bottom-left" reverseOrder={false} />
      <LeadCardWrapperStyle $borderColor={borderColor} ref={ref}>
        <LeadCardBodyStyle
          background={
            !data?.events_map?.first_call
              ? colors?.white?.clean
              : PriorityMap[
                  [undefined, null]?.includes(data?.priority as any)
                    ? 0
                    : (data?.priority as Priorities)
                ].background
          }
        >
          <FlexColumn gap="16px" padding="16px">
            <FlexColumn dimensions={{ height: "50px" }}>
              {callerListHoverIndex === undefined ? (
                <>
                  {!agent && (
                    <FlexRow
                      alignItems="center"
                      gap="5px"
                      dimensions={{ height: "100%" }}
                    >
                      <Badge
                        background={theme.colors.warning}
                        padding="4px"
                        dimensions={{ width: "16px" }}
                        border={{ radius: "50%" }}
                      >
                        !
                      </Badge>
                      <Text size={`${theme.font.size.x}px`}>
                        {!data?.events_map?.first_call && (
                          <>Waiting for first contact</>
                        )}
                        {data?.events_map?.first_call && <>Free lead</>}
                      </Text>
                    </FlexRow>
                  )}
                  {agent && (
                    <Badge
                      background={theme.colors.clean}
                      padding="5px 10px"
                      border={{
                        top: `solid 2px ${borderColor}`,
                        left: `solid 2px ${borderColor}`,
                        right: `solid 2px ${borderColor}`,
                        bottom: `solid 2px ${borderColor}`,
                        radius: "4px",
                      }}
                      dimensions={{ height: "100%" }}
                    >
                      <FlexRow
                        alignItems="center"
                        gap="5px"
                        dimensions={{ width: "100%", height: "100%" }}
                        style={{ transition: "all 0.5s" }}
                      >
                        <Image
                          padding="2px"
                          /* TODO Move background colors in constants */
                          background={"#b0e7c7"}
                          border={{
                            radius: "50%",
                            width: "2px",
                            color: theme.colors.success,
                          }}
                          src={
                            avatarUrl
                              ? avatarUrl
                              : "https://www.w3schools.com/howto/img_avatar.png"
                          }
                          dimensions={{ width: "25px", height: "25px" }}
                        />
                        <Text size={`${theme.font.size.x}px`}>
                          {agent?.first_name} {agent?.last_name}
                        </Text>
                      </FlexRow>
                    </Badge>
                  )}
                </>
              ) : (
                <>
                  <Badge
                    background={theme.colors.clean}
                    padding="5px 10px"
                    border={{
                      top: `solid 2px ${theme.colors.primary}`,
                      left: `solid 2px ${theme.colors.primary}`,
                      right: `solid 2px ${theme.colors.primary}`,
                      bottom: `solid 2px ${theme.colors.primary}`,
                      radius: "4px",
                    }}
                    dimensions={{ height: "100%" }}
                  >
                    <FlexRow
                      alignItems="center"
                      gap="5px"
                      dimensions={{ width: "100%", height: "100%" }}
                      style={{ transition: "all 0.5s" }}
                    >
                      <Text size={`${theme.font.size.x}px`} weight="bold">
                        {toReadableDateOnly(
                          (data?.calls as Event[])[callerListHoverIndex]
                            ?.created_at
                        )}
                      </Text>
                    </FlexRow>
                  </Badge>
                </>
              )}
            </FlexColumn>

            {/* Shipment date */}
            <Text size={`${theme.font.size.xl}px`} weight="bold">
              {toReadableDate(data?.ship_date as Date)}
            </Text>
            {/* First Contact */}
            {firstCallDelta && (
              <Badge
                dimensions={{ maxWidth: "100px", width: "max-content" }}
                background={
                  firstCallDelta <= MINIMUM_REQUIRED_CALL_TIME_TRESHOLD
                    ? theme.colors.success
                    : theme.colors.danger
                }
              >
                <Text
                  align="center"
                  size={`${theme.font.size.xl}px`}
                  weight="bold"
                  color={colors.white.clean}
                >
                  {(firstCallDelta / 1000).toFixed(2)}
                </Text>
              </Badge>
            )}

            {/* Priority switch control and current one */}
            <FlexRow margin={{ top: "auto" }} gap="10px">
              {Object.keys(PriorityMap).map((priority) => (
                <Button
                  border={{
                    color:
                      data?.priority && `${data?.priority}` === priority
                        ? theme.font.color
                        : "transparent",
                  }}
                  background={PriorityMap[priority as any]?.color}
                  dimensions={{ width: "28px", height: "28px" }}
                  onClick={async () => {
                    try {
                      await httpUpdateLead(data?.id as number, {
                        priority: Number(priority),
                      });
                      toast.success("Priority Updated", {
                        style: {
                          border: `1px solid ${theme.colors.primary}`,
                          padding: "16px",
                          boxShadow: "none",
                          borderRadius: "24px",
                        },
                        iconTheme: {
                          primary: `${theme.colors.primary}`,
                          secondary: `${theme.colors.clean}`,
                        },
                      });
                    } catch (err) {
                      console.log("httpUpdateLead", err);
                    }
                  }}
                />
              ))}
            </FlexRow>
          </FlexColumn>

          {/* Caller List */}
          <FlexColumn
            dimensions={{ width: "max-content" }}
            overflow={{ x: "hidden", y: "auto" }}
            margin={{ left: "auto" }}
            css={css`
              cursor: pointer;

              &::-webkit-scrollbar {
                margin-right: 2px;
                width: 4px;
                cursor: pointer;
              }

              &::-webkit-scrollbar-thumb {
                background-color: ${theme.colors
                  .primary}; /* Color of the thumb */
                border-radius: 5px; /* Rounded corners */
              }

              &::-webkit-scrollbar-track {
                background-color: transparent;
              }
              &::-webkit-scrollbar-track:hover {
                background-color: ${colors.white.gallery};
              }
            `}
          >
            {(data?.calls ?? [])?.map((caller, index) => {
              const callerAvatarUrl = getAvatarUrl(
                caller?.user?.first_name as string,
                caller?.user?.last_name as string,
                String(caller?.user?.id as number)
              );

              return (
                <FlexRow
                  key={`caller-${data?.id}-${index}`}
                  alignItems="center"
                  justifyContent="flex-start"
                  gap="5px"
                  onMouseEnter={() => {
                    console.log(index);
                    setCallerListHoverIndex(index);
                  }}
                  onMouseLeave={() => setCallerListHoverIndex(undefined)}
                  padding={"4px 4px 4px 0px"}
                >
                  <Image
                    padding="2px"
                    /* TODO Move background colors in constants */
                    background={"#b0e7c7"}
                    border={{
                      radius: "50%",
                      width: "2px",
                      color: theme.colors.success,
                    }}
                    src={
                      callerAvatarUrl ??
                      "https://www.w3schools.com/howto/img_avatar.png"
                    }
                    dimensions={{ width: "16px", height: "16px" }}
                  />
                  <Text size={`${theme.font.size.sx}px`}>
                    {caller?.user?.first_name} {caller?.user?.last_name}
                  </Text>
                </FlexRow>
              );
            })}
          </FlexColumn>
          <FlexColumn
            dimensions={{ flex: 1, maxWidth: "1000px" }}
            padding="16px"
            justifyContent="flex-end"
            alignItems="center"
            background={`url('${data?.map_box_route_static_url}')`}
            style={{
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: "cover",
              cursor: data?.map_box_route_static_url ? "pointer" : undefined,
              position: "relative",
            }}
            onClick={() => {
              if (!data?.map_box_route_static_url) return;
              window.open(
                `/map?origin_latitude=${data?.origin_latitude}&origin_longitude=${data?.origin_longitude}&destination_latitude=${data?.destination_latitude}&destination_longitude=${data?.destination_longitude}`,
                "Route Map",
                "directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=800,height=600"
              );
            }}
            border={
              data?.map_box_route_static_url
                ? {
                    left: `solid 1px ${borderColor}`,
                    right: `solid 1px ${borderColor}`,
                  }
                : {}
            }
            margin={{ right: "auto" }}
          >
            <LeadCardLocation>
              <FlexRow dimensions={{ flex: 1 }} justifyContent="flex-end">
                <Text
                  onClick={handleCopyOriginPostalCode}
                  wordBreak="keep-all"
                  size={`${theme.font.size.sx}px`}
                >
                  {data?.origin_city}, {data?.origin_state} (
                  {data?.origin_postal_code})
                </Text>
              </FlexRow>
              <Icon type={IconTypes.location} color={theme.colors.primary} />
              <FlexRow dimensions={{ flex: 1 }} justifyContent="flex-start">
                <Text
                  onClick={handleCopyDestinationPostalCode}
                  wordBreak="keep-all"
                  size={`${theme.font.size.sx}px`}
                >
                  {data?.destination_city}, {data?.destination_state} (
                  {data?.destination_postal_code})
                </Text>
              </FlexRow>
            </LeadCardLocation>
          </FlexColumn>
          <FlexColumn
            justifyContent="space-between"
            padding="16px"
            margin={{ left: "auto" }}
          >
            <FlexRow justifyContent="flex-end" gap="10px">
              <Select
                onTop={true}
                enableHoverEffect={true}
                placeholder="SELECT SMS"
                style={{
                  valueWrapper: {
                    dimensions: { width: "fit-content", height: "32px" },
                    padding: { left: "8px", right: "8px" },
                    css: css`
                      color: white;
                    `,
                  },
                }}
                options={SMS_TEMPLATE_OPTIONS}
                closeOnSelectOption={true}
                setValue={async (value: string) => {
                  try {
                    if (value === "SHORT_SMS") {
                      await navigator.clipboard.writeText(
                        injectMessagingValues(SHORT_SMS, data as any)
                      );
                      toast.success("Short SMS Copied", {
                        style: {
                          border: `1px solid ${theme.colors.primary}`,
                          padding: "16px",
                          boxShadow: "none",
                          borderRadius: "24px",
                        },
                        iconTheme: {
                          primary: `${theme.colors.primary}`,
                          secondary: `${theme.colors.clean}`,
                        },
                      });
                      return;
                    }
                    if (value === "LONG_SMS") {
                      const vehicle = `${data?.vehicles?.[0]?.vehicle_model_year} ${data?.vehicles?.[0]?.vehicle_make} ${data?.vehicles?.[0]?.vehicle_model}`;
                      const agent = `${currentUser?.first_name}`;
                      const number =
                        currentUser && currentUser.phone_number
                          ? currentUser.phone_number
                          : "(512) 583-6611";
                      const price = `${data?.events_map?.latest_price?.pricing?.price}`;
                      await navigator.clipboard.writeText(
                        injectMessagingValues(LONG_SMS, {
                          vehicle: vehicle,
                          agent_name: agent,
                          agent_number: number,
                          price: price,
                          ...data,
                        } as any)
                      );
                      toast("Long SMS Copied", {
                        style: {
                          border: `1px solid ${theme.colors.primary}`,
                          padding: "16px",
                          boxShadow: "none",
                          borderRadius: "24px",
                        },
                        iconTheme: {
                          primary: `${theme.colors.primary}`,
                          secondary: `${theme.colors.clean}`,
                        },
                      });
                      return;
                    }
                  } catch (error) {
                    console.error("Error copying value: ", error);
                  }
                }}
              />
              <Select
                onTop={true}
                enableHoverEffect={true}
                placeholder="SELECT EMAIL"
                style={{
                  valueWrapper: {
                    dimensions: { width: "fit-content", height: "32px" },
                    padding: { left: "8px", right: "8px" },
                    css: css`
                      color: white;
                    `,
                  },
                }}
                options={(emailTemplates ?? []).map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
                closeOnSelectOption={true}
                setValue={(value: number) => {
                  const targetEmailTemplate = emailTemplates.find(
                    (item) => item.id === value
                  );
                  if (!targetEmailTemplate) return;

                  setState({
                    ...state,
                    emailBoxCollapsed: value,
                  });
                }}
              />

              {state?.emailBoxCollapsed && (
                <LeadEmailCard
                  key={data?.id as number}
                  template={state.emailBoxCollapsed}
                  lead={data as Lead}
                  close={() =>
                    setState({
                      ...state,
                      emailBoxCollapsed: undefined,
                    })
                  }
                />
              )}
            </FlexRow>
            <FlexRow justifyContent="flex-end" position="relative">
              <Button
                styleType={
                  !state?.notesCollapsed
                    ? ButtonTypes?.primary
                    : ButtonTypes.primaryReverse
                }
                dimensions={{ minWidth: "150px", height: "35px" }}
                onClick={() =>
                  setState({ ...state, notesCollapsed: !state?.notesCollapsed })
                }
              >
                NOTES ({notes?.length})
              </Button>
              {state?.notesCollapsed && (
                <LeadNotes
                  leadId={data?.id as number}
                  notes={data?.notes || []}
                  close={() =>
                    setState({
                      ...state,
                      notesCollapsed: !state?.notesCollapsed,
                    })
                  }
                />
              )}
            </FlexRow>
            <FlexRow justifyContent="flex-end" gap="10px">
              <Button
                dimensions={{ minWidth: "85px", height: "35px" }}
                disabled={
                  !priceCalculator || priceCalculator.price === -10000000
                }
              >
                <FlexColumn
                  justifyContent="center"
                  alignItems="center"
                  dimensions={{ width: "100%", height: "100%" }}
                >
                  {!priceCalculator && (
                    <Loading dimensions={{ width: "16px", height: "16px" }} />
                  )}
                  {priceCalculator && priceCalculator.price !== -10000000 && (
                    <>{priceCalculator?.price} $</>
                  )}
                  {priceCalculator && priceCalculator.price === -10000000 && (
                    <>No recommendation</>
                  )}
                </FlexColumn>
              </Button>
              {inputPrice && (
                <>
                  <form
                    style={{ margin: 0, padding: 0 }}
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <StyledNumberInput
                      onKeyDown={(event) => {
                        if (event.key === "Escape") {
                          event.preventDefault();
                          event.stopPropagation();
                          setInputPrice(false);
                          return;
                        }

                        if (event.key === "Enter") {
                          event.preventDefault();
                          event.stopPropagation();
                          handleSubmit(onSubmit)();
                        }
                      }}
                      {...register("price")}
                    />
                  </form>
                </>
              )}
              {!inputPrice && (
                <Button
                  justifyContent="center"
                  alignItems="center"
                  disabled={currentUser?.id !== data?.agent?.id}
                  onClick={() => {
                    if (data?.events_map?.latest_price !== undefined || null) {
                      setInputPrice(true);
                    }
                  }}
                  dimensions={{ minWidth: "85px", height: "35px" }}
                >
                  {data?.events_map?.latest_price?.pricing?.price || (
                    <>ASSIGN PRICE</>
                  )}
                  {!!data?.events_map?.latest_price?.pricing?.price && <> $</>}
                </Button>
              )}

              {/* TODO Add back when feature is completed */}
              {/* <Button>RECOMMENDED PRICES ({routePrices?.length})</Button> */}
            </FlexRow>
          </FlexColumn>
        </LeadCardBodyStyle>
        <LeadCardFooter
          onClick={() =>
            setState && setState({ ...state, isCollapsed: !state?.isCollapsed })
          }
          background={borderColor}
        >
          <Text
            color={colors?.white?.clean}
            weight="bold"
            size={`${theme.font.size.xxl}px`}
          >
            {data?.first_name} {data?.last_name}
          </Text>
          <FlexItem dimensions={{ flex: 1 }}>
            {/* Empty Element for spacing */}
          </FlexItem>

          {[25, 50, 75, 100]?.map((radiusType) => {
            return (
              <Button
                dimensions={{ width: "46px", height: "32px" }}
                styleType={ButtonTypes?.clean}
                disabled={
                  !(data as any)?.extra_data?.[
                    `price_search_radius_${radiusType}`
                  ]
                }
                onClick={(event) => {
                  event?.stopPropagation();
                  window.open(
                    (data?.extra_data as any)[
                      `price_search_radius_${radiusType}`
                    ],
                    "_blank"
                  );
                  //TODO Implement Radius button actions
                  //TODO Open radius button link in new tab
                }}
              >
                <FlexColumn
                  dimensions={{ width: "100%", height: "100%" }}
                  justifyContent="center"
                  alignItems="center"
                >
                  {!(data as any)?.extra_data?.[
                    `price_search_radius_${radiusType}`
                  ] && (
                    <Loading dimensions={{ width: "20px", height: "20px" }} />
                  )}
                  {(data as any)?.extra_data?.[
                    `price_search_radius_${radiusType}`
                  ] && <>{radiusType}</>}
                </FlexColumn>
              </Button>
            );
          })}

          {/* Unclaim Lead */}
          {agent && agent?.id === currentUser?.id ? (
            <Button
              styleType={ButtonTypes?.primaryReverse}
              dimensions={{ width: "200px", height: "32px" }}
              onClick={(event) => {
                event?.stopPropagation();
                try {
                  httpUnclaimLead(data?.id as number);
                } catch (err) {
                  toast.error("Error unclaiming lead", {
                    style: {
                      border: `1px solid ${theme.colors.danger}`,
                      padding: "16px",
                      boxShadow: "none",
                      borderRadius: "24px",
                    },
                    iconTheme: {
                      primary: `${theme.colors.danger}`,
                      secondary: `${theme.colors.clean}`,
                    },
                  });
                  console.log("httpUnclaimLead", err);
                }
              }}
            >
              UNCLAIM
            </Button>
          ) : (
            <Button
              styleType={ButtonTypes?.naked}
              dimensions={{ width: "200px", height: "32px" }}
            ></Button>
          )}
          {/* Claim Lead */}
          {!agent && data?.events_map?.first_call && (
            <Button
              styleType={ButtonTypes?.clean}
              dimensions={{ width: "200px", height: "32px" }}
              onClick={(event) => {
                event?.stopPropagation();
                try {
                  httpClaimLead(data?.id as number);
                } catch (err) {
                  toast.error("Error claiming/un claiming lead", {
                    style: {
                      border: `1px solid ${theme.colors.danger}`,
                      padding: "16px",
                      boxShadow: "none",
                      borderRadius: "24px",
                    },
                    iconTheme: {
                      primary: `${theme.colors.danger}`,
                      secondary: `${theme.colors.clean}`,
                    },
                  });
                  console.log("httpClaimLead/httpUnclaimLead", err);
                }
              }}
            >
              CLAIM
            </Button>
          )}

          {/* Call */}
          <Button
            styleType={ButtonTypes?.clean}
            dimensions={{ width: "200px", height: "32px" }}
            onClick={(event) => {
              event?.stopPropagation();
              try {
                (
                  event?.currentTarget?.querySelector("a") as HTMLAnchorElement
                ).click();
                if (!data?.agent)
                  httpClaimLead(data?.id as number).then(() =>
                    httpUpdateLead(data?.id as number, { priority: 0 })
                  );
              } catch (err) {
                toast.error("Errorclaiming lead", {
                  style: {
                    border: `1px solid ${theme.colors.danger}`,
                    padding: "16px",
                    boxShadow: "none",
                    borderRadius: "24px",
                  },
                  iconTheme: {
                    primary: `${theme.colors.danger}`,
                    secondary: `${theme.colors.clean}`,
                  },
                });
                console.log("httpClaimLead", err);
              }
            }}
          >
            <a
              style={{ display: "none" }}
              href={`tel:${(data?.phone as string).replace(/\D/g, "")}`}
              onClick={(event) => {
                event?.stopPropagation();
                //TODO Make call first conntact API
                httpTriggerCall(data?.id as number);
              }}
            />
            {parsePhoneNumber(data?.phone as string)}
          </Button>
        </LeadCardFooter>
        <LeadCardDetailsStyle isCollapsed={state?.isCollapsed}>
          <FlexColumn dimensions={{ flex: 1 }} justifyContent="space-between">
            <FlexColumn gap="1px">
              <Text size={`${theme.font.size.xxl}px`} weight="bold">
                NAME:
              </Text>
              <Text
                cursor="pointer"
                size={`${theme.font.size.xxl}px`}
                onClick={async (
                  event: React.MouseEvent<HTMLSpanElement, MouseEvent>
                ) => {
                  if (!data) return;
                  const name = `${data.first_name} ${data.last_name}`;
                  try {
                    await navigator.clipboard.writeText(name);
                    toast.success("Customer Name Copied", {
                      style: {
                        border: `1px solid ${theme.colors.primary}`,
                        padding: "16px",
                        boxShadow: "none",
                        borderRadius: "24px",
                      },
                      iconTheme: {
                        primary: `${theme.colors.primary}`,
                        secondary: `${theme.colors.clean}`,
                      },
                    });
                  } catch (error) {
                    toast.error("Error copying agent name", {
                      style: {
                        border: `1px solid ${theme.colors.danger}`,
                        padding: "16px",
                        boxShadow: "none",
                        borderRadius: "24px",
                      },
                      iconTheme: {
                        primary: `${theme.colors.danger}`,
                        secondary: `${theme.colors.clean}`,
                      },
                    });
                  }
                  event.stopPropagation();
                }}
              >
                {data?.first_name} {data?.last_name}
              </Text>
            </FlexColumn>
            <FlexColumn gap="1px">
              <Text size={`${theme.font.size.xxl}px`} weight="bold">
                EMAIL:
              </Text>
              <Text
                cursor="pointer"
                size={`${theme.font.size.xxl}px`}
                onClick={async (
                  event: React.MouseEvent<HTMLSpanElement, MouseEvent>
                ) => {
                  if (!data) return;
                  const email = `${data.email}`;
                  try {
                    await navigator.clipboard.writeText(email);
                    toast.success("E-mail Copied", {
                      style: {
                        border: `1px solid ${theme.colors.primary}`,
                        padding: "16px",
                        boxShadow: "none",
                        borderRadius: "24px",
                      },
                      iconTheme: {
                        primary: `${theme.colors.primary}`,
                        secondary: `${theme.colors.clean}`,
                      },
                    });
                  } catch (error) {
                    toast.error("Error copying e-mail", {
                      style: {
                        border: `1px solid ${theme.colors.danger}`,
                        padding: "16px",
                        boxShadow: "none",
                        borderRadius: "24px",
                      },
                      iconTheme: {
                        primary: `${theme.colors.danger}`,
                        secondary: `${theme.colors.clean}`,
                      },
                    });
                  }
                  event.stopPropagation();
                }}
              >
                {data?.email}
              </Text>
            </FlexColumn>
            <FlexColumn gap="1px">
              <Text size={`${theme.font.size.xxl}px`} weight="bold">
                PHONE:
              </Text>
              <Text
                cursor="pointer"
                size={`${theme.font.size.xxl}px`}
                onClick={async (
                  event: React.MouseEvent<HTMLSpanElement, MouseEvent>
                ) => {
                  if (!data) return;
                  const phone = parsePhoneNumber(data?.phone as string);
                  try {
                    await navigator.clipboard.writeText(extractNumbers(phone));
                    toast.success("Phone Number Copied", {
                      style: {
                        border: `1px solid ${theme.colors.primary}`,
                        padding: "16px",
                        boxShadow: "none",
                        borderRadius: "24px",
                      },
                      iconTheme: {
                        primary: `${theme.colors.primary}`,
                        secondary: `${theme.colors.clean}`,
                      },
                    });
                  } catch (error) {
                    toast.error("Error copying phone number", {
                      style: {
                        border: `1px solid ${theme.colors.danger}`,
                        padding: "16px",
                        boxShadow: "none",
                        borderRadius: "24px",
                      },
                      iconTheme: {
                        primary: `${theme.colors.danger}`,
                        secondary: `${theme.colors.clean}`,
                      },
                    });
                  }
                  event.stopPropagation();
                }}
              >
                {parsePhoneNumber(data?.phone as string)}
              </Text>
            </FlexColumn>
            <FlexColumn gap="1px">
              <Text size={`${theme.font.size.xxl}px`} weight="bold">
                LAST UPDATE:
              </Text>
              <Text size={`${theme.font.size.xxl}px`}>
                {toReadableDate(data?.updated_at as Date)}
              </Text>
            </FlexColumn>
          </FlexColumn>
          <FlexColumn dimensions={{ flex: 1 }} justifyContent="space-between">
            <FlexColumn gap="1px">
              <FlexRow>
                <Text size={`${theme.font.size.xxl}px`} weight="bold">
                  VEHICLE:
                </Text>
              </FlexRow>
              <Text
                cursor="pointer"
                size={`${theme.font.size.xxl}px`}
                onClick={() => {
                  const value = [
                    data?.vehicles?.[0]?.vehicle_model_year,
                    data?.vehicles?.[0]?.vehicle_make,
                    data?.vehicles?.[0]?.vehicle_model,
                  ].join("+");
                  const searchUrl = `https://www.google.com/search?q=${value}&hl=en&tbm=isch`;
                  window.open(searchUrl, "_blank");
                }}
              >
                {data?.vehicles?.[0]?.vehicle_model_year}{" "}
                {data?.vehicles?.[0]?.vehicle_make}{" "}
                {data?.vehicles?.[0]?.vehicle_model}
              </Text>
            </FlexColumn>
            <FlexColumn gap="1px">
              <Text size={`${theme.font.size.xxl}px`} weight="bold">
                CONDITION:
              </Text>
              <Text size={`${theme.font.size.xxl}px`}>
                {data?.vehicles?.[0]?.vehicle_inop ===
                OperableType?.inoperable ? (
                  <>inoperable</>
                ) : (
                  <>operable</>
                )}
              </Text>
            </FlexColumn>
            <FlexColumn gap="1px">
              <Text size={`${theme.font.size.xxl}px`} weight="bold">
                SIZE:
              </Text>
              <Text size={`${theme.font.size.xxl}px`}>
                {data?.vehicles?.[0]?.vehicle_type}
              </Text>
            </FlexColumn>
          </FlexColumn>
          <FlexColumn
            dimensions={{ flex: 1 }}
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <FlexColumn gap="1px">
              <Text
                size={`${theme.font.size.xxl}px`}
                weight="bold"
                align="right"
              >
                FROM:
              </Text>
              <Text
                size={`${theme.font.size.xxl}px`}
                align="right"
                cursor="pointer"
                onClick={handleCopyOriginPostalCode}
              >
                {data?.origin_city}, {data?.origin_state} (
                {data?.origin_postal_code})
              </Text>
            </FlexColumn>
            <FlexColumn gap="1px">
              <Text
                size={`${theme.font.size.xxl}px`}
                weight="bold"
                align="right"
              >
                TO:
              </Text>
              <Text
                size={`${theme.font.size.xxl}px`}
                align="right"
                cursor="pointer"
                onClick={handleCopyDestinationPostalCode}
              >
                {data?.destination_city}, {data?.destination_state} (
                {data?.destination_postal_code})
              </Text>
            </FlexColumn>
            <FlexColumn gap="1px">
              <Text
                size={`${theme.font.size.xxl}px`}
                weight="bold"
                align="right"
              >
                MOVE DATE:
              </Text>
              <Text size={`${theme.font.size.xxl}px`} align="right">
                {toReadableDateOnly(data?.ship_date as Date)}
              </Text>
            </FlexColumn>
            <FlexColumn gap="1px">
              <Text
                size={`${theme.font.size.xxl}px`}
                weight="bold"
                align="right"
              >
                PROVIDER:
              </Text>
              <Text size={`${theme.font.size.xxl}px`} align="right">
                {data?.provider?.code}
              </Text>
            </FlexColumn>
          </FlexColumn>
        </LeadCardDetailsStyle>
      </LeadCardWrapperStyle>
    </>
  );
};
